import { Component, Prop, Vue } from 'vue-property-decorator';
import { Line as LineChartComp } from 'vue-chartjs/legacy';
import {
  ChartData,
  ChartOptions,
  Chart as ChartJs,
  Tooltip,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { COLOR_LIGHT } from '@/constants/color.constant';

ChartJs.defaults.font.size = 10;
ChartJs.register(
  Tooltip,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
);

@Component({
  name: 'LineChart',
  components: {
    LineChartComp
  },
})
export default class LineChart extends Vue {
  @Prop({
    required: true
  })
  chartData!: ChartData;

  @Prop({
    required: false
  })
  options!: ChartOptions;

  @Prop({
    required: false,
    default: () => 'var(--table-tr-bg)'
  })
  backgroundColor!: string;

  @Prop({
    type: Number,
    required: false
  })
  height?: number;

  @Prop({
    type: Number,
    required: false,
  })
  width?: number;

  defaultOptions: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: 'center',
        labels: {
          usePointStyle: true,
          boxWidth: 9
        }
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: COLOR_LIGHT,
        }
      },
      y: {
        grid: {
          display: false,
          color: COLOR_LIGHT,
        },
        ticks: {
          callback: (label: number | string) => Math.floor(label as number) === label ? label : ''
        },
        min: 0,
        beginAtZero: true,
        afterFit: () => 1050
      }
    }
  };

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.options
    }
  }

  get styles() {
    return {
      backgroundColor: this.backgroundColor,
      width: '100%',
    }
  }

  get canvasWidth() {
    return this.width;
  }

  get canvasHeight() {
    return this.height;
  }
}

import { Component, Prop, Vue } from 'vue-property-decorator';
import LineChart from '@/components/ui/charts/line-chart/lineChart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { COLOR_DARK, COLOR_GREEN_10_BASE, COLOR_LIGHT, COLOR_RED_20_BASE } from '@/constants/color.constant';
import { numberFormatter } from '@/helpers/string.helper';
import { isMobile } from '@/helpers/app.helper';

@Component({
  name: 'SalesAcosChart',
  components: {
    LineChart,
  },
})
export default class SalesAcosChart extends Vue {
  @Prop({ required: true })
  data: any;

  @Prop({ required: false })
  height?: number;

  get chartData(): ChartData {
    return {
      labels: Object.keys(this.data).map(item => item),
      datasets: [
        {
          label: 'ACoS, %',
          type: 'line',
          data: Object.values(this.data).map((item: any) => item.acos ? (item.acos * 100).toFixed(0) : 0) as any,
          borderColor: COLOR_RED_20_BASE,
          backgroundColor: COLOR_RED_20_BASE,
          yAxisID: 'y2',
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'PPC sales, $',
          type: 'bar',
          data: Object.values(this.data).map((item: any) => item.ppcSales) as any,
          borderColor: COLOR_GREEN_10_BASE,
          backgroundColor: COLOR_GREEN_10_BASE,
          yAxisID: 'y1'
        },
      ]
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = COLOR_DARK;
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              const sign = meta.yAxisID === 'y2' ? '%' : '$';
              meta.data.forEach((bar, index) => {
                const value = meta.yAxisID === 'y2'
                  ? item.data?.[index]
                  : numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                const padding = meta.yAxisID === 'y2' ? 5 : -(bar as any).height;
                ctx.fillText(
                  item.data?.[index] ? `${value} ${sign}` : '',
                  bar.x,
                  bar.y - padding
                );
              });
            }
          })
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
        },
        y1: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
          beginAtZero: true,
          afterFit: () => 1050
        },
        y2: {
          position: 'right',
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
          beginAtZero: true,
          afterFit: () => 1050,
        }
      }
    }
  }

  get chartWidth() {
    return isMobile() ? 1200 : undefined;
  }
}

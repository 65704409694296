import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';

interface AutocompleteInputItem {
  value: number | string;
  text: string;
}

@Component({
  name: 'AutocompleteInput',
  components: { BTextInputValidation }
})
export default class AutocompleteInput extends Vue {
  innerValue: string = '';
  items: AutocompleteInputItem[] = [];
  isShowList = false;
  selectedItem?: AutocompleteInputItem;

  @Prop({
    type: Array,
    required: true
  })
  options!: AutocompleteInputItem[];

  @Prop({
    default: () => null
  })
  value?: string;

  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  @Watch('value')
  valueChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.innerValue = '';
    }
  }

  @Watch('innerValue')
  innerValueChange(newVal) {
    const value = this.selectedItem?.value || '';
    this.$emit('input', newVal ? value : newVal);
    if (!newVal) {
      this.selectedItem = undefined;
      this.$emit('choose', newVal);
    }
  }

  @Watch('options')
  optionsChange(newVal: any) {
    this.items = newVal;
    this.setSelectedItem(newVal);
  }

  created() {
    if (this.value && this.options.length) {
      this.setSelectedItem(this.options);
    }
    this.items = this.options || [];
    this.hideList = debounce(this.hideList, 170);
  }

  setSelectedItem(options: AutocompleteInputItem[]) {
    if (this.value) {
      this.selectedItem = options.find(item => item.value?.toString() === this.value!.toString());
      this.innerValue = this.selectedItem?.text || '';
    } else {
      this.innerValue = '';
    }
  }

  onKeyUp() {
    if (this.innerValue) {
      this.items = this.options.filter(itm => itm.text.toLowerCase().includes(this.innerValue.toLowerCase()));
    } else {
      this.items = this.options;
    }
  }

  hideList() {
    this.isShowList = false;
  }

  onFocus() {
    if (this.items.length) {
      this.isShowList = true;
    }
  }

  onFocusOut() {
    this.hideList();
  }

  choose(item: AutocompleteInputItem) {
    this.isShowList = false;
    this.selectedItem = item;
    this.innerValue = item.value ? item.text : '';
    this.$emit('input', item.value);
    this.$emit('choose', item.value);
  }

  getItemClass(item: AutocompleteInputItem) {
    return { active: item.value === this.selectedItem?.value }
  }
}

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateRangePicker from 'vue2-daterange-picker';
import { DateTime, Info } from 'luxon';
import { DATE_FORMAT, DATE_TIME_FORMAT, DATEPICKER_FORMAT } from '@/helpers/date.helper';
import { Getter } from 'vuex-class';
import isFunction from 'lodash/isFunction';
import { DateObjectUnits } from 'luxon/src/datetime';

export interface DateRangeValues {
  startDate: null | string | Date;
  endDate: null | string | Date;
}

@Component({
  components: { DateRangePicker }
})
export default class DatepickerSingle extends Vue {
  dateRange: DateRangeValues = {
    startDate: null,
    endDate: null,
  };
  localeData: any;
  ranges: any = false;

  @Prop({
    type: String,
    default: null
  })
  date?;

  @Prop({
    type: String,
    default: null
  })
  size?;

  @Prop({
    type: Boolean
  })
  menuTop?;

  @Prop({
    required: false,
  })
  customRanges?;

  @Prop({
    required: false,
    default: false
  })
  rangesOnly?;

  @Prop({
    required: false
  })
  showingFormat?;

  @Prop({
    required: false
  })
  toFormat?;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  changeDatepickerLocale(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.initLocaleData();
      this.$forceUpdate();
    }
  }

  @Watch('date')
  startDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.dateRange.startDate = newVal;
      this.dateRange.endDate = newVal;
    }
  }

  created() {
    this.dateRange.startDate = this.date ? this.datePrepare(this.date) : null;
    this.dateRange.endDate = this.date ? this.datePrepare(this.date) : null;
    this.initLocaleData();
  }

  clickOut() {
    document.body.click();
  }

  updateDateValues(dateRange: DateRangeValues) {
    const date = this.dateToISO(dateRange.startDate);

    this.$emit('update:date', date);

    if (this.$listeners.update && isFunction(this.$listeners.update)) {
      this.$listeners.update(date);
    }
  }

  dateFormatter(value) {
    const format = Object.prototype.hasOwnProperty.call(this.$attrs, 'time-picker') ? DATE_TIME_FORMAT : DATE_FORMAT;
    const showingFormat = this.showingFormat || format;
    return value ? DateTime.fromJSDate(value).toFormat(showingFormat) : '';
  }

  datePrepare(value) {
    return DateTime.fromFormat(value, DATEPICKER_FORMAT).toJSDate();
  }

  dateToISO(value, units: DateObjectUnits = { hour: 0, minute: 0, second: 0 }) {
    if (value && 'time-picker' in this.$attrs) {
      const date = DateTime.fromJSDate(value);
      return DateTime.fromJSDate(value)
        .set({
          hour: date.get('hour'),
          minute: date.get('minute'),
          second: 0,
        })
        .toFormat(DATEPICKER_FORMAT)
    }

    return value
      ? DateTime.fromJSDate(value, { zone: 'utc' })
        .set(units)
        .toUTC()
        .toFormat(DATEPICKER_FORMAT)
      : null;
  }

  initLocaleData() {
    const weekdays = Info.weekdays('short');
    this.localeData = {
      firstDay: 1,
      format: 'yyyy-mm-dd',
      applyLabel: this.$t('datePicker.apply'),
      cancelLabel: this.$t('datePicker.cancel'),
      weekLabel: this.$t('datePicker.w'),
      customRangeLabel: this.$t('datePicker.customRange'),
      daysOfWeek: [weekdays[weekdays.length - 1], ...weekdays.slice(0, weekdays.length - 1)],
      monthNames: Info.months('long'),
    };
  }

  get classes() {
    return {
      [`form-control-${this.size}`]: this.size,
    };
  }
}
